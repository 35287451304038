<!-- main content -->

<app-header ></app-header>
<div class="ept"><!--Div faz com que mapa fique no centro da pagina-->
    <main id="eptMain" class="ept-main ept-mt--s">
    
        <app-index></app-index>
    
    </main>

    
</div>
<app-footer></app-footer>
